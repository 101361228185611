import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/public/Login.vue'
import LostPassword from "../views/public/LostPassword";
import ResetPassword from "../views/public/ResetPassword";
import store from '../store';
import AccountActivation from "../views/public/AccountActivation";
import privateRoutes from "./private";
import PasswordCreation from "../views/public/PasswordCreation";
import UIKit from "../views/public/UIKit"
import ErrorView from "../views/public/ErrorView"
import userService from "../services/user";

Vue.use(VueRouter)

/* {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
    },*/

const routes = [{
    path: '/',
    redirect: '/login',
}, {
    name: 'app.public.login',
    path: '/login',
    component: Login,
    meta: {
        redirectToPrivateIfLogin: true,
    },
}, {
    name: 'app.public.lostPassword',
    path: '/lost-password',
    component: LostPassword,
    meta: {
        redirectToPrivateIfLogin: false, // true
    },
}, {
    name: 'app.public.resetPassword',
    path: '/reset-password/:guid',
    component: ResetPassword,
    meta: {
        redirectToPrivateIfLogin: false, // true
    },
    props: true,
}, {
    name: 'app.public.accountActivation',
    path: '/account-activation',
    component: AccountActivation,
    meta: {
        redirectToPrivateIfLogin: false, // true
    },
}, {
    name: 'app.public.password-creation',
    path: '/password-creation/:guid',
    props: true,
    component: PasswordCreation,
    meta: {
        redirectToPrivateIfLogin: false, // true
    }
}, {
    name: 'app.public.uikit',
    path: '/uikit',
    component: UIKit,
}, {
    name: 'app.public.error',
    path: '/error',
    props: true,
    component: ErrorView,
}, privateRoutes, {
    path: '/*',
    redirect: '/login',
}];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.authRequired)) {
        if (!store.getters['auth/isSetLocalUser']) {
            userService.getMyself().then(res => {
                store.commit('auth/setLocalUser', {user: res.data});
                userService.setLocalUser(res.data);
                next();
            }).catch(() => {
                next({name: 'app.public.login', query: {redirect: to.fullPath}})
            });
        } else {
            next();
        }
    } else {
        next();
    }
});


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.redirectToPrivateIfLogin)) {
        if (store.getters['auth/isSetLocalUser']) {
            next({name: 'app.private'});
        } else {
            userService.getMyself().then(res => {
                store.commit('auth/setLocalUser', {user: res.data});
                userService.setLocalUser(res.data);
                next({name: 'app.private'});
            }).catch(() => {
                next();
            })
        }
    } else {
        next();
    }
});

export default router
