<template>
    <div class="legends">
        <div id="distance-legend" class="legend">
            <div class="legend-title">
                MISS DISTANCE
            </div>
            <div class="items">
                <div class="legend-label">
                    0m
                </div>
                <div class="legend-item" />
                <div class="legend-item" />
                <div class="legend-item" />
                <div class="legend-item" />
                <div class="legend-label">
                    +{{ maxDistance }}m
                </div>
            </div>
        </div>
        <div id="probability-legend" class="legend">
            <div class="legend-title">
                MAX COLLISION PROBABILITY
            </div>
            <div class="items">
                <div class="legend-label">
                    1e-2
                </div>
                <div class="legend-item" />
                <div class="legend-label straddle">
                    <span>1e-3</span>
                </div>
                <div class="legend-item" />
                <div class="legend-label straddle">
                    <span>1e-4</span>
                </div>
                <div class="legend-item" />
                <div class="legend-label straddle">
                    <span>1e-5</span>
                </div>
                <div class="legend-item" />
                <div class="legend-label straddle">
                    <span>1e-6</span>
                </div>
                <div class="legend-item" />
                <div class="legend-label">
                    <span>1e-7</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VizLegends',
    props: {
        maxDistance: {
            type: Number,
            required: true,
        }
    }
}
</script>
