<template>
    <div>
        <p>Event Details</p>
    </div>
</template>

<script>
export default {
    name: 'EventDetails'
}
</script>
