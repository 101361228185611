<template>
    <div class="error-layout">
        <div class="layout-content">
            <div class="content-header">
                <a href="https://www.aldoria.com" target="_blank" class="logo" />
            </div>
            <div class="content-body">
                <p class="text-error">
                    {{ errorMessage }}
                </p>

                <router-link class="link link-2" :to="{name: 'app.public.login'}">
                    &lt;&nbsp;Back home
                </router-link>
            </div>
        </div>
        <div class="layout-image" />
    </div>
</template>

<script>
export default {
    name: 'ErrorView',
    props: {
        errorMessage: {
            type: String,
            default: 'An error has occurred '
        },
    },
}
</script>
