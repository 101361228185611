import {extend, validate} from 'vee-validate';
// See https://logaretm.github.io/vee-validate/guide/rules.html#rules for available rules
// import {x} from 'vee-validate/dist/rules'
import {confirmed, email, required} from 'vee-validate/dist/rules';

extend('confirmed', {
    ...confirmed,
    message: "{_field_} does not match. Please try again."
});

extend('email', {
    ...email,
    message: "Please enter a valid email"
});

extend('required', {
    ...required,
    message: "{_field_} is required"
});

// This is a sample rule
extend('minLength', {
    validate(val, {min}) {
        return val.length >= min;
    },
    computesRequired: true,
    params: ['min'],
    message: 'The {_field_} field must have {min} characters at least',
});

const regexMaj = new RegExp(/(?=.*[A-Z])/);
extend('hasMaj', {
    validate(str) {
        return (!str.search(regexMaj))
    },
    message: 'The {_field_} field must contain a capital letter',
});

const regNumber = new RegExp(/(?=.*[0-9])/);
extend('hasNumber', {
    validate(str) {
        return (!str.search(regNumber))
    },
    message: 'The {_field_} field must contain a number',
});

extend('maxLength', {
    validate(val, {max}) {
        return val.length <= max;
    },
    computesRequired: true,
    params: ['max'],
    message: 'The {_field_} field must have {max} characters at most',
});

const regSpecChars = new RegExp(/(?=.*[@$!%*#?&^()\-_=+[\]{}|\\/;:'",.<>~`¤£µ°§])/);
extend('hasSpecialChar', {
    validate(str) {
        return !str.search(regSpecChars);
    },
    message: 'The {_field_} field must contain a special character',
});

extend('password', {
    validate: (val) => {
        return validate(val, 'hasNumber|hasSpecialChar|minLength:8')
    },
    message: "Your password must be at least 8 characters, including one numeric digit and one special character. Please try again."
})
