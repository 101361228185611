<template>
    <div class="auth-layout">
        <div class="layout-content">
            <div class="content-header">
                <a href="https://www.aldoria.com" target="_blank" class="logo" />
                <h1 class="page-title">
                    Lost Password
                </h1>
            </div>
            <div class="content-body">
                <ValidationObserver v-slot:="{handleSubmit, invalid}">
                    <form @submit.prevent="handleSubmit(() => resetPwd({email}))">
                        <div>
                            <ValidationProvider
                                v-slot="{ errors, classes }"
                                rules="email|required"
                                slim
                            >
                                <div class="input-label-container">
                                    <label class="form-label" :class="classes" for="e-mail">Email</label>
                                    <input
                                        id="e-mail"
                                        v-model="email"
                                        name="Email"
                                        class="input"
                                        :class="classes"
                                        placeholder="e.g. john.doe@mail.com"
                                        type="email"
                                    >
                                </div>
                                <div v-show="invalid" class="form-validation-msgs">
                                    <p class="text-error">
                                        {{ errors[0] }}
                                    </p>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="form-actions">
                            <button class="btn btn-1">
                                Send reset link
                            </button>
                            <div>
                                <router-link class="link link-2" :to="{name: 'app.public.login'}">
                                    &lt;&nbsp;Back to login
                                </router-link>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <div class="layout-image" />
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {mapActions} from "vuex";

export default {
    name: "LostPassword",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            email: '',
        }
    },
    methods: {
        ...mapActions({
            resetPwd: 'auth/lostPassword',
        }),
    }
}
</script>
