<template>
    <div>
        <slot />
    </div>
</template>

<script>
export default {
    name: "Debounce",
    props: {
        debounceDuration: {
            type: Number,
            default: 500
        },
        // eslint-disable-next-line vue/require-prop-types
        debouncedValue: {
            required: true,
        },
    },
    data() {
        return {
            debounceHandler: null,
        };
    },
    watch: {
        debouncedValue() {
            if (this.debounceHandler !== null) clearTimeout(this.debounceHandler);
            this.debounceHandler = setTimeout(() => this.$emit('onComplete', this.debouncedValue), this.debounceDuration);
        },
    },
}
</script>
