<template>
    <div class="container page-content">
        <h1 class="page-title">
            Your account
        </h1>
        <ValidationObserver v-slot="{errors, handleSubmit, invalid}">
            <form class="account-form" @submit.prevent="handleSubmit(() => updateMyUser(user))">
                <div class="row">
                    <div class="col col-1-2 capped">
                        <!-- If email is editable : rules="required|email" -->
                        <ValidationProvider v-slot="{classes}" slim>
                            <div class="input-label-container">
                                <label class="form-label" :class="classes" for="email">Email (login) *</label>
                                <p id="email" :class="classes" name="Email">
                                    {{ user.email }}
                                </p>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-1-2 capped">
                        <ValidationProvider v-slot="{classes}" rules="required" slim>
                            <div class="input-label-container">
                                <label class="form-label" :class="classes" for="lastname">Lastname *</label>
                                <input id="lastname" v-model="user.lastname" class="input" :class="classes" name="Lastname">
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col col-1-2 capped">
                        <ValidationProvider v-slot="{classes}" rules="required" slim>
                            <label class="form-label" :class="classes" for="firstname">Firstname *</label>
                            <input id="firstname" v-model="user.firstname" class="input" :class="classes" name="Firstname">
                        </ValidationProvider>
                    </div>
                </div>

                <div v-show="invalid" class="form-validation-msgs">
                    <div v-for="(errSlot, slotIndex) in errors" :key="slotIndex">
                        <p v-for="(err, errorIndex) in errSlot" :key="errorIndex" class="text-error">
                            {{ err }}
                        </p>
                    </div>
                </div>

                <div class="form-actions">
                    <button class="btn btn-1">
                        UPDATE ACCOUNT
                    </button>
                </div>
            </form>
        </ValidationObserver>

        <hr>

        <h2 class="page-subtitle">
            Change your password
        </h2>
        <ValidationObserver v-slot="{errors, handleSubmit, invalid}">
            <form class="account-form" @submit.prevent="handleSubmit(() => updateMyPassword({newPwd: password}))">
                <div class="row">
                    <div class="col col-1-2 capped">
                        <ValidationProvider v-slot="{classes}" rules="required|password" vid="password" slim>
                            <div class="input-label-container">
                                <label class="form-label" :class="classes" for="password">New password *</label>
                                <input
                                    id="password" v-model="password" class="input"
                                    :class="classes" name="New password"
                                    type="password"
                                >
                            </div>
                        </ValidationProvider>
                        <p class="input-tip">
                            8 characters min. including at least one numeric digit and one special character.
                        </p>
                    </div>
                    <div class="col col-1-2 capped">
                        <ValidationProvider v-slot="{classes}" rules="required|confirmed:password" slim>
                            <div class="input-label-container">
                                <label class="form-label" :class="classes" for="passwordConfirmation">Password confirmation *</label>
                                <input
                                    id="passwordConfirmation" v-model="confirmPassword" class="input"
                                    :class="classes" name="Password confirmation"
                                    type="password"
                                >
                            </div>
                        </ValidationProvider>
                    </div>
                </div>

                <div v-show="invalid" class="form-validation-msgs">
                    <div v-for="(errSlot, slotIndex) in errors" :key="slotIndex" class="error-container">
                        <p v-for="(err, errorIndex) in errSlot" :key="errorIndex" class="text-error">
                            {{ err }}
                        </p>
                    </div>
                </div>

                <div class="form-actions">
                    <button class="btn btn-2">
                        CHANGE PASSWORD
                    </button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {mapActions} from "vuex";
import store from '../../store'

export default {
    name: 'Account',
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data() {
        return {
            password: '',
            confirmPassword: '',
            user: {...store.state.auth.user}
        }
    },
    methods: {
        ...mapActions('auth', [
            'updateMyPassword',
            'updateMyUser'
        ]),
    },
}
</script>
