<template>
    <div>
        <vue-progress-bar />
        <notifications position="top right" classes="notification" />

        <router-view />
    </div>
</template>

<script>
import "./assets/less/main.less";

export default {
    name: 'App',
}
</script>
