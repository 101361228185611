import axios from 'axios';
import store from '../store';
import app from '../main';
import Notification from "../Notification";

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    xsrfCookieName: "XSRF-TOKEN2",
    url: '/',
    headers: {},
    timeout: 30000,
    withCredentials: true,
});

let requestNumber = 0;
service.interceptors.request.use(config => {
    requestNumber++;
    app.$Progress.start();
    console.info("===> services/API.js ::: config", config);
    return config;
}, error => {
    // eslint-disable-next-line no-console
    console.error("===> services/API.js ::: Error while starting request with config", error.config);
    return Promise.reject(error);
});

service.interceptors.response.use(response => {
    requestNumber--;
    if (requestNumber === 0) app.$Progress.finish();

    // eslint-disable-next-line no-console
    console.info('===> services/API.js ::: Received Response from ' + response.config.url, response.data);

    return response;
}, error => {
    requestNumber--;
    if (requestNumber === 0) app.$Progress.finish(); // app.$Progress.fail();

    if (error.response && error.response.config) {
        // eslint-disable-next-line no-console
        console.warn("===> services/API.js ::: Received error from " + error.response.config.url, error, error.response);
    } else {
        // eslint-disable-next-line no-console
        console.warn("===> services/API.js ::: Received error : ", error);
    }

    if (error.response) {
        if (typeof error.response.status === 'number' && error.response.status === 403) {
            Notification.error("Access denied !");
        } else if (typeof error.response.status === 'number' && error.response.status === 401) {
            store.dispatch('auth/logout');
        } else if (typeof error.response.status === 'number' && error.response.status === -1) {
            Notification.error("Timeout", "Server is unreachable or does not respond properly !");
        }
    }

    return Promise.reject(error);
});

export default service;
