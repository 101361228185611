<template>
    <div class="container page-content">
        <div class="section">
            <label class="form-label">
                Source(s)
                <div class="grid">
                    <div class="col-auto">
                        <vue-multi-select v-model="selectedOems" class="input-form-height" :options="orbitalSafetyDataOems" placeholder="" />
                    </div>
                    <div class="col-auto">
                        <UploadEphem tabname="osp" />
                    </div>
                </div>
            </label>
        </div>
        <div>
            <div class="section grid">
                <div class="col-m-1-2">
                    <VizLegends :max-distance="maxDistance" />
                </div>
                <div class="col-m-1-2">
                    <div>
                        <label class="form-label">
                            Start Date:
                        </label>
                        <div class="grid">
                            <div class="col-s-1-2">
                                <select class="select input-form-height" :value="startTimeFilter" @change="setStartTimeFilter">
                                    <option :value="null">
                                        -
                                    </option>
                                    <option value="greater">
                                        ≥
                                    </option>
                                    <option value="smaller">
                                        ≤
                                    </option>
                                </select>
                            </div>
                            <div class="col-s-1-2">
                                <input class="input input-form-height" type="date" :value="startTimeValue" @change="setStartTimeValue">
                            </div>
                        </div>
                    </div>
                    <div class="section-s">
                        <label class="form-label">
                            End Date:
                        </label>
                        <div class="grid">
                            <div class="col-s-1-2">
                                <select class="select input-form-height" :value="endTimeFilter" @change="setEndTimeFilter">
                                    <option :value="null">
                                        -
                                    </option>
                                    <option value="greater">
                                        ≥
                                    </option>
                                    <option value="smaller">
                                        ≤
                                    </option>
                                </select>
                            </div>
                            <div class="col-s-1-2">
                                <input class="input input-form-height" type="date" :value="endTimeValue" @change="setEndTimeValue">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <OrbitalSafetyPanelViz v-for="oem in filteredOrbitalSafetyData" :key="oem.oem_id" :data="oem" :max-distance="maxDistance" @onMarkerClick="openPanel" />
            </div>
        </div>
        <transition name="slide">
            <OrbitalSafetyPopinPanel v-if="selectedEvent" :event="selectedEvent" :max-distance="maxDistance" @onClose="openPanel(null)" />
        </transition>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import OrbitalSafetyPopinPanel from "../../components/OrbitalSafetyPopinPanel.vue";
import VizLegends from "../../components/VizLegends.vue";
import OrbitalSafetyPanelViz from "../../components/OrbitalSafetyPanelViz.vue";
import moment from "moment";
import VueMultiSelect from "vue-simple-multi-select";
import UploadEphem from "../../components/UploadEphem.vue";

export default {
    name: 'OrbitalSafetyPanel',
    components: {
        OrbitalSafetyPopinPanel,
        VizLegends,
        OrbitalSafetyPanelViz,
        VueMultiSelect,
        UploadEphem
    },
    data() {
        return {
            maxDistance: 20000,
            selectedEvent: null,
            selectedOems: [],
            startTimeFilter: null,
            startTimeValue: null,
            endTimeFilter: null,
            endTimeValue: null,
        }
    },
    computed: {
        ...mapState('event', ['orbitalSafetyData']),
        filteredOrbitalSafetyData() {
            const startDate = this.startTimeFilter !== null && moment(this.startTimeValue).isValid() ? moment(this.startTimeValue) : null;
            const endDate = this.endTimeFilter !== null && moment(this.endTimeValue).isValid() ? moment(this.endTimeValue) : null;

            return this.orbitalSafetyData.filter(oem => {
                const oemStartDate = moment(oem.start_time);
                const oemEndDate = moment(oem.end_time);

                if (startDate !== null) {
                    if (this.startTimeFilter === 'greater' && startDate.isAfter(oemStartDate)) return false;
                    if (this.startTimeFilter === 'smaller' && startDate.isBefore(oemStartDate)) return false;
                }

                if (endDate !== null) {
                    if (this.endTimeFilter === 'greater' && endDate.isAfter(oemEndDate)) return false;
                    if (this.endTimeFilter === 'smaller' && endDate.isBefore(oemEndDate)) return false;
                }

                return this.selectedOems.length === 0 || this.selectedOems.includes(oem.oem_id);
            });
        },
        orbitalSafetyDataOems() {
            return this.orbitalSafetyData.map(oem => oem.oem_id);
        }
    },
    methods: {
        ...mapMutations('event', ['setSelectedDate']),
        openPanel(eventId) {
            const clickedEvent = this.findEventById(eventId)
            this.selectedEvent = this.selectedEvent === clickedEvent ? null : clickedEvent;
        },
        findEventById(eventId) {
            return this.orbitalSafetyData.flatMap((oem) => oem.Scenario.Conjunctions.map(conj => ({
                ...conj,
                Oem: oem,
                sourceT: conj.Scenario.Ephem_source.source_name,
                sourceC: conj.ScenarioC.Ephem_source.source_name,
            }))).find((conj) => conj.event_id === eventId);
        },
        updateDate(newDate) {
            this.setSelectedDate(newDate);
        },
        setStartTimeFilter(ev) {
            this.startTimeFilter = ev.target.value;
        },
        setStartTimeValue(ev) {
            this.startTimeValue = ev.target.value;
        },
        setEndTimeFilter(ev) {
            this.endTimeFilter = ev.target.value;
        },
        setEndTimeValue(ev) {
            this.endTimeValue = ev.target.value;
        },
    }
}
</script>
