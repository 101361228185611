import API from "./API";

export default {
    getAllEvents() {
        return API.get('calm/conjunctions/list')
    },

    getAllSources() {
        return API.get('calm/sources')
    },

    getFleet(date) {
        return API.get(`calm/conjunctions/vizz/${date}`);
    },

    /**
     * @param {number | string} conjunctionId
     * @return Promise<AxiosResponse<any>>
     */
    getManeuvers(conjunctionId, date) {
        return API.get(`calm/conjunctions/${conjunctionId}/maneuvers/${date}`);
    },

    getOrbitalSafetyData() {
        return API.get('calm/oem/conjunctions');
    },
}
