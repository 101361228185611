<template>
    <div class="event-panel">
        <div class="container">
            <button class="link close-panel" @click="$emit('onClose')">
                <IconBase width="28" height="28" icon-name="IconClose">
                    <IconClose />
                </IconBase>
            </button>
            <p class="panel-title">
                CLOSE APPROACH
            </p>
            <div class="event-date">
                {{ event.tca | formatDate('YYYY/MM/DD - HH:mm:ss.SSS') }}
            </div>
            <div class="event-infos">
                <p><strong>Origin: </strong>{{ event.originator }}</p>
                <p><strong>Filename: </strong>{{ event.Oem.filename.substring(event.Oem.filename.lastIndexOf("/") + 1) }}</p>
                <p><strong>Creation Date: </strong>{{ event.Oem.creation_date | formatDate('YYYY/MM/DD - HH:mm:ss.SSS') }}</p>
                <p><strong>Source: </strong>{{ event.sourceT }}</p>
            </div>
            <span class="panel-subtitle">Between <hr class="line-separator"></span>
            <p class="satelitte-name">
                {{ event.Event.Satellite.name }}
            </p>
            <div class="event-infos">
                <p><strong>NORAD ID: {{ event.Event.Satellite.sat_id }}</strong></p>
                <p><strong>Int'l code: </strong>{{ event.Event.Satellite.cospar }}</p>
                <p><strong>Source: </strong>{{ event.sourceT }}</p>
                <p><strong>Approximate altitude: </strong>{{ altitude | formatDistance(0) }} km</p>
            </div>
            <span class="panel-subtitle">And <hr class="line-separator"></span>
            <p class="satelitte-name">
                {{ event.Event.Object.name }}
            </p>
            <div class="event-infos">
                <p><strong>NORAD ID: </strong>{{ event.Event.Object.sat_id }}</p>
                <p><strong>Int'I Code: </strong>{{ event.Event.Object.cospar }}</p>
                <p><strong>Source: </strong>{{ event.sourceC }}</p>
            </div>
            <IconBase class="icon" width="50" height="50" icon-name="SelectTriangle">
                <SelectTriangle />
            </IconBase>
            <p class="panel-subtitle">
                Latest Prediction
            </p>
            <p class="event-date">
                {{ event.prediction_date | formatDate('YYYY/MM/DD - HH:mm:ss.SSS') }}
            </p>
            <div class="event-infos">
                <p><strong>See conjunction:  </strong><a :href="'https://calm-visualization.aldoria.com/conjunction/' + event.conjunction_id" target="_blank" class="visualization">3D Visualization</a></p>
                <p><strong>Miss distance: </strong>{{ event.miss_distance | formatDistance }}m</p>
                <p><strong>Rel. inertial speed: </strong>{{ event.relative_velocity | formatDistance }}m/s</p>
                <p><strong>Collision probability : </strong>{{ event.collision_probability | formatProbability }}</p>
                <p><strong>Max. collision probability: </strong>{{ event.collision_probability_max | formatProbability }}</p>
                <p><strong>Tangential (in track): </strong>{{ event.dt | formatDistance }}m</p>
                <p><strong>Cross track: </strong>{{ event.dw | formatDistance }}m</p>
                <p><strong>Normal: </strong>{{ event.dn | formatDistance }}m</p>
            </div>
            <div v-if="event.Conjunctions">
                <button class="link link-2 margin-top-s" @click="showHistory = !showHistory">
                    History
                </button>
                <div v-show="showHistory">
                    <p v-for="conjunction in event.Conjunctions" :key="conjunction.conjunction_id" class="history-infos">
                        <strong><em style="color: #555555;">[{{ conjunction.prediction_date | formatDate('YYYY/MM/DD - HH:mm:ss') }}]</em></strong><br>
                        <strong>{{ conjunction.tca | formatDate('YYYY/MM/DD - HH:mm:ss') }} (TCA)</strong> / {{ sourceTarget(conjunction) }}-{{ sourceChaser(conjunction) }} / {{ conjunction.miss_distance | formatDistance }}m / {{ conjunction.relative_velocity | formatDistance }}m/s / {{ conjunction.collision_probability | formatProbability }} / {{ conjunction.collision_probability_max | formatProbability }} / {{ conjunction.dt | formatDistance }}m  / {{ conjunction.dw | formatDistance }}m  / {{ conjunction.dn | formatDistance }}m
                    </p>
                </div>
            </div>
            <div v-if="event.Conjunctions" class="event-infos">
                <p class="panel-subtitle">
                    Schedule observation
                </p>
                <div class="btn-cleo-div">
                    <button class="btn-cleo" @click="gotoCleo()">
                        CLEO
                    </button>
                </div>
            </div>
            <button class="btn btn-1" @click="$emit('onClose')">
                Close
            </button>
        </div>
    </div>
</template>

<script>
import formatDate from "../filters/formatDate"
import formatDistance from "../filters/formatDistance"
import formatProbability from "../filters/formatProbability"
import IconBase from "./icons/IconBase"
import IconClose from "./icons/IconClose"
import SelectTriangle from "./icons/SelectTriangle"
import {mapState} from "vuex";

export default {
    name: 'OrbitalSafetyPopinPanel',
    filters: {
        formatDate,
        formatDistance,
        formatProbability
    },
    components: {
        IconBase,
        IconClose,
        SelectTriangle
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        maxDistance: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            showHistory: false,
            showSatellitePanel: true,
        }
    },
    computed: {
        ...mapState('auth', ['user']),
        altitude() {
            return this.event.distance * Math.pow(10, -3);
        }
    },
}
</script>
