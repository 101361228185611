<template>
    <div class="event-panel">
        <div class="container">
            <button class="link close-panel" @click="$emit('onClose')">
                <IconBase width="28" height="28" icon-name="IconClose">
                    <IconClose />
                </IconBase>
            </button>
            <p class="panel-title">
                CLOSE APPROACH
            </p>
            <div class="event-date">
                {{ event.tca | formatDate('YYYY/MM/DD - HH:mm:ss.SSS') }}
            </div>
            <span class="panel-subtitle">Between <hr class="line-separator"></span>
            <p class="satelitte-name">
                {{ satellite.name }}
            </p>
            <div class="event-infos">
                <p><strong>NORAD ID: </strong>{{ satellite.sat_id }}</p>
                <p><strong>Int'l code: </strong>{{ satellite.cospar }}</p>
                <p><strong>Approximate altitude: </strong>{{ altitude | formatDistance(0) }} km</p>
                <p><strong>Source: </strong>{{ event.sourceT }}</p>
            </div>
            <span class="panel-subtitle">And <hr class="line-separator"></span>
            <p class="satelitte-name">
                {{ event.Object.name }}
            </p>
            <div class="event-infos">
                <p><strong>NORAD ID: </strong>{{ event.Object.sat_id }}</p>
                <p><strong>Int'l code: </strong>{{ event.Object.cospar }}</p>
                <p><strong>Source: </strong>{{ event.sourceC }}</p>
            </div>
            <IconBase class="icon" width="50" height="50" icon-name="SelectTriangle">
                <SelectTriangle />
            </IconBase>
            <p class="panel-subtitle">
                Latest Prediction
            </p>
            <p class="event-date">
                {{ event.prediction_date | formatDate('YYYY/MM/DD - HH:mm:ss.SSS') }}
            </p>


            <div class="event-infos">
                <p><strong>See conjunction:  </strong><a :href="'https://calm-visualization.aldoria.com/conjunction/' + event.conjunction_id" target="_blank" class="visualization">3D Visualization</a></p>
                <p><strong>Miss distance: </strong>{{ event.miss_distance | formatDistance }}m</p>
                <p><strong>Rel. inertial speed: </strong>{{ event.relative_velocity | formatDistance }}m/s</p>
                <p><strong>Collision probability: </strong>{{ event.collision_probability | formatProbability }}</p>
                <p><strong>Max. collision probability: </strong>{{ event.collision_probability_max | formatProbability }}</p>
                <p><strong>Tangential (in track): </strong>{{ event.dt | formatDistance }}m</p>
                <p><strong>Cross track: </strong>{{ event.dw | formatDistance }}m</p>
                <p><strong>Normal: </strong>{{ event.dn | formatDistance }}m</p>
            </div>

            <button class="btn btn-1" @click="$emit('onClose')">
                Close
            </button>
        </div>
    </div>
</template>

<script>
import formatDate from "../filters/formatDate"
import formatDistance from "../filters/formatDistance"
import formatProbability from "../filters/formatProbability"
import IconBase from "./icons/IconBase"
import IconClose from "./icons/IconClose"
import SelectTriangle from "./icons/SelectTriangle"


export default {
    name: 'PopinPanel',
    filters: {
        formatDate,
        formatDistance,
        formatProbability
    },
    components: {
        IconBase,
        IconClose,
        SelectTriangle
    },
    props: {
        satellite: {
            type: Object,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showHistory: false,
            showSatellitePanel: true,
        }
    },
    computed: {
        altitude() {
            return this.event.distance * Math.pow(10, -3)
        }
    },
}
</script>
