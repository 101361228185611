<template>
    <div class="uikit-layout">
        <h1 class="page-title">
            UIKIT
        </h1>
        <div class="uikit-block-container">
            <h1 class="heading-1 block-title">
                TYPOGRAPHY
            </h1>
            <div class="uikit-block-item">
                <h1 class="heading-1">
                    Heading 1
                </h1>
                <p>Poppins, bold, 2rem</p>
                <pre>.heading-1</pre>
            </div>
            <div class="uikit-block-item">
                <h2 class="heading-2">
                    Heading 2
                </h2>
                <p>Poppins, semi-bold, 1.7rem</p>
                <pre>.heading-2</pre>
            </div>
            <div class="uikit-block-item">
                <p class="body">
                    BODY
                </p>
                <p>Poppins, regular, 0.9rem</p>
                <pre>.body</pre>
            </div>
            <div class="uikit-block-item">
                <p class="body-small">
                    SMALL BODY
                </p>
                <p>Poppins, regular, 0.75rem</p>
                <pre>.body-small</pre>
            </div>
            <div class="uikit-block-item">
                <p><strong>TEXT MODIFIERS</strong></p>
                <p class="text-error">
                    TEXT ERROR
                </p>
                <p>font-weight 500, color red (inherits everything else)</p>
                <pre>.text-error</pre>
            </div>
            <div class="uikit-block-item">
                <p><strong>TEXT LAYOUTS</strong></p>
                <h1>Lorem ipsum dolor sit amet <br>et consectur</h1>
                <h2>Lorem ipsum dolor sit amet <br>et consectur</h2>
                <p class="body-small">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas dolor ea natus debitis incidunt iusto tempora molestiae quisquam aut amet at, vel rerum illo totam praesentium consectetur nostrum est corrupti.
                </p>
                <p>Lorem ipsum dolor, <strong>sit amet consectetur</strong> adipisicing elit. Magni deleniti sapiente quidem magnam, sequi similique vel, veritatis obcaecati culpa doloribus necessitatibus numquam sint optio at aperiam dolor laudantium. Similique, soluta.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae iste possimus, nihil vitae impedit distinctio rerum dolorem adipisci ex incidunt ut, nobis explicabo voluptatum perspiciatis in reprehenderit facere, officiis id?</p>
            </div>
        </div>
        <div class="uikit-block-container">
            <h1 class="heading-1 block-title">
                FORMS
            </h1>
            <form>
                <label class="form-label">Email</label>
                <input class="input" type="text">
                <p class="input-tip">
                    8 caractères min, etc...
                </p>
                <pre>label.form-label+input.input+.input-tip</pre>
                <label class="form-label invalid">Password</label>
                <input class="input invalid" type="password">
                <pre>input.input.invalid / select.select.invalid</pre>
                <label class="form-label">Satellite</label>
                <select class="select">
                    <option value="">
                        Make your choice
                    </option>
                    <option value="">
                        1
                    </option>
                    <option value="">
                        2
                    </option>
                </select>
                <pre>select.select>option</pre>
            </form>
            <p class="text-error">
                Password is required
            </p>
            <p>(cf. typography / text modifiers)</p>
            <pre>.text-error</pre>
        </div>
        <div class="uikit-block-container">
            <h1 class="heading-1 block-title">
                BUTTONS
            </h1>
            <a class="btn btn-1">btn-1</a>
            <pre>.btn.btn-1</pre>
            <a class="btn btn-2">btn-2</a>
            <pre>.btn.btn-2</pre>
        </div>
        <div class="uikit-block-container">
            <h1 class="heading-1 block-title">
                LINKS
            </h1>
            <a class="link link-1">link-1</a>
            <pre>.link.link-1</pre>
            <a class="link link-2">link-2</a>
            <pre>.link.link-2</pre>
        </div>
    </div>
</template>

<script>
export default {
    name: "UIKit",
}
</script>
