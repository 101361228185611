<template>
    <div class="container page-content">
        <div class="row">
            <div class="col col-1-4">
                <label class="form-label" for="satellite-select">Satellite</label>
                <select id="satellite-select" v-model="selectedSatId" class="select" name="satellite-select">
                    <option :value="null">
                        ALL
                    </option>
                    <option v-for="satellite in sortedSatellites" :key="satellite.sat_id" :value="satellite.sat_id">
                        {{ satellite.name }}
                    </option>
                </select>
            </div>
            <div class="col col-1-4">
                <label class="form-label" for="max-distance">Max. distance (m)</label>
                <input
                    id="max-distance" v-model="maxDistance" type="number"
                    name="max-distance" class="input"
                    min="0"
                >
            </div>
        </div>

        <button class="link link-2" @click="showPopup = true">
            <small> Download data </small>
        </button>
        <a href="https://calmapi.aldoria.com/" target="_blank" style="text-decoration: none; margin-left: 1em; color: #5f93d5;"><small>API Documentation</small></a>

        <div v-if="filteredEvents.length === 0" class="no-data-msg">
            <hr class="line-separator">
            <p v-if="events.length === 0">
                No close approach event was detected for the next five days. Cataloged objects are not threatening your satellite(s) at the moment.
            </p>
            <p v-else>
                There is no result matching these criteria
            </p>
            <hr class="line-separator">
        </div>

        <div v-for="eventDate in eventsByDate" :key="eventDate.date.valueOf()">
            <h4 class="list-title">
                {{ eventDate.date | formatDate('ll') }} <!-- TODO: check if wanted -->
            </h4>

            <ul class="list events-list">
                <li v-for="event in eventDate.eventList" :key="event.eventId" class="list-item clickable" @click="openPanel(event)">
                    <div class="badge">
                        {{ Math.round(event.Conjunctions ? event.Conjunctions[0].miss_distance : event.miss_distance) }}m
                    </div>
                    <div>
                        <p class="item-title">
                            <strong>{{ (event.Conjunctions ? event.Conjunctions[0].tca : event.tca) | formatDate('HH:mm:ss.SSS') }} | {{ event.Satellite.name }} ({{ event.Conjunctions ? event.Conjunctions[0].originator : event.originator }})</strong>
                        </p>
                        <p>close approach with <strong>{{ (event.Object && event.Object.name) ? event.Object.name : 'Analyst Object' }}</strong></p>
                        <p><strong v-if="event.Conjunctions">Prob.: {{ event.Conjunctions[0].collision_probability | formatProbability }} / Max. Prob.: {{ event.Conjunctions[0].collision_probability_max | formatProbability }}</strong></p>
                    </div>
                </li>
            </ul>
        </div>

        <transition name="slide">
            <PopinPanel v-if="selectedEvent" :event="selectedEvent" :max-distance="maxDistance" @onClose="openPanel(null)" />
        </transition>

        <DownloadDataPopup
            v-show="showPopup"
            :last-event-date="lastEventDate"
            @cancel="showPopup = false"
            @confirm="downloadData"
        />
    </div>
</template>

<script>
import PopinPanel from "../../components/PopinPanel"
import {mapState} from "vuex"
import moment from "moment"
import formatDate from "../../filters/formatDate"
import formatProbability from "../../filters/formatProbability"
import DownloadDataPopup from "../../components/DownloadDataPopup"
import fileService from "../../services/files"
import errorService from "../../services/errors"

export default {
    name: 'EventList',
    components: {
        PopinPanel,
        DownloadDataPopup
    },
    filters: {
        formatDate,
        formatProbability
    },
    data() {
        return {
            showPopup: false,
            selectedEvent: null,
            maxDistance: null,
            selectedSatId: null,
        }
    },
    computed: {
        ...mapState('event', ['events']),
        ...mapState('event', {
            sortedSatellites(state) {
                return [...state.satellites].sort((c1, c2) => c1.name.localeCompare(c2.name))
            }
        }),
        /*
        * Create a copy of the events array, changing the data model to sort the events by Dates
        * @returns
        [
            {
                date: Moment ,
                eventList: [ Event ]
            }
        ]
        */
        eventsByDate() {
            const eventsByDate = [];

            // - 1 - Grouping events by day
            for (const event of this.filteredEvents) {
                //  Converting current event to a moment for date manipulations
                const currentEventDate = moment.utc(event.Conjunctions ? event.Conjunctions[0].tca : event.tca)

                let dateIndex = 0
                for (const eventListAtDate of eventsByDate) {
                    if (eventListAtDate.date.isSame(currentEventDate, 'day')) break;
                    dateIndex++
                }

                //  Checking if we found at least another event occuring the same day
                if (eventsByDate[dateIndex]) {
                    eventsByDate[dateIndex].eventList.push(event)
                } else {
                    eventsByDate[dateIndex] = {
                        "date": currentEventDate,
                        "eventList": [event],
                    }
                }
            }

            // - 2 - sorting events by ascending date
            eventsByDate.sort((eventListAtDate1, eventListAtDate2) => eventListAtDate1.date.diff(eventListAtDate2.date))

            for (const eventListAtDate of eventsByDate) {
                eventListAtDate.eventList.sort((event1, event2) => moment(event2.Conjunctions ? event2.Conjunctions[0].tca : event2.tca).diff(moment(event1.Conjunctions ? event1.Conjunctions[0].tca : event1.tca)))
            }

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            // this.selectedSatId = this.$store.state.event.selectedSatId,
            return eventsByDate
        },
        filteredEvents() {
            let newEvents = [...this.events]
            if (this.selectedSatId) newEvents = newEvents.filter(event => event.Satellite.sat_id === this.selectedSatId)
            if (this.maxDistance) newEvents = newEvents.filter(event => ((event.Conjunctions ? event.Conjunctions[0].miss_distance : event.miss_distance) <= this.maxDistance))
            return newEvents
        },
        lastEventDate() {
            return this.eventsByDate.length !== 0 ? this.eventsByDate[this.eventsByDate.length - 1].date.format('YYYY-MM-DD') : null
        }
    },
    watch: {
        sortedSatellites() {
            this.selectedSatId = null;
            this.maxDistance = null;
        },
    },
    methods: {
        openPanel(event) {
            this.selectedEvent = this.selectedEvent === event ? null : event;
        },
        downloadData(timelapse) {
            if (timelapse.endDate && timelapse.startDate === timelapse.endDate) timelapse.endDate = moment(timelapse.endDate).add(1, 'd').format('YYYY-MM-DD')
            fileService.downloadData(timelapse).then(
                () => this.showPopup = false
                , errorService.genericErrorNotification
            )
        },
    },
}
</script>
