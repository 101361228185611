import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueProgressBar from "vue-progressbar"
import Notifications from "vue-notification";
import './validations';
import { setInteractionMode, configure } from 'vee-validate';

setInteractionMode('passive')
configure({
    classes: true,
})

Vue.use(VueProgressBar, { // See https://github.com/hilongjw/vue-progressbar#constructor-options for customization
    thickness: '8px',
    autoFinish: false,
    transition: {speed: '0.4s', opacity: '0.6s', termination: 300},
});

Vue.use(Notifications);

Vue.config.productionTip = false;

const vm = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

export default vm
