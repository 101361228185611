<template>
    <div class="section txt-center">
        <Debounce :debounced-value="tempDate" @onComplete="(debouncedValue) => $emit('onUpdateDate', debouncedValue.toDate())">
            <button class="btn btn-1 btn-small" @click="previousDay">
                &lt;
            </button>
            <span class="body-medium date-switcher">
                {{ formattedDate }}
            </span>
            <button class="btn btn-1 btn-small" @click="nextDay">
                &gt;
            </button>
        </Debounce>
    </div>
</template>

<script>
import moment from "moment";
import Debounce from "@/components/Debounce";

export default {
    name: 'DateSwitcher',
    components: {Debounce},
    props: {
        date: {
            type: Date,
            required: true,
        }
    },
    data() {
        return {
            tempDate: moment(this.date).set({hour: 0, minute: 0, second: 0, millisecond: 0}),
        };
    },
    computed: {
        formattedDate() {
            return this.tempDate.format("YYYY/MM/DD");
        },
    },
    methods: {
        updateDate(addDays) {
            this.tempDate = moment(this.tempDate.add(addDays, 'days'));
        },
        previousDay() {
            this.updateDate(-1);
        },
        nextDay() {
            this.updateDate(1);
        },
    },
}
</script>
