<template>
    <div class="auth-layout">
        <div class="layout-content">
            <div class="content-header">
                <a href="https://www.aldoria.com/" target="_blank" class="logo" />
                <h1 class="page-title">
                    Login
                </h1>
                <p>
                    First time here ? <router-link class="link link-2" :to="{name: 'app.public.accountActivation'}">
                        Activate your account !
                    </router-link>
                </p>
            </div>
            <div class="content-body">
                <ValidationObserver v-slot="{errors, handleSubmit, invalid}">
                    <form @submit.prevent="handleSubmit(() => login(user))">
                        <ValidationProvider v-slot="{ classes } " rules="required|email" slim>
                            <div class="input-label-container">
                                <label for="e-mail" class="form-label" :class="classes">Email</label>
                                <input
                                    id="e-mail"
                                    v-model="user.email"
                                    class="input"
                                    :class="classes"
                                    name="Email"
                                    type="email"
                                >
                            </div>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{ classes }" rules="required|password" slim>
                            <div class="input-label-container">
                                <label for="password" class="form-label" :class="classes">Password</label>
                                <input
                                    id="password"
                                    v-model="user.password"
                                    class="input"
                                    :class="classes"
                                    name="Password"
                                    type="password"
                                >
                            </div>
                        </ValidationProvider>
                        <p class="input-tip">
                            8 characters min. including at least one numeric digit and one special character.
                        </p>
                        <div v-show="invalid" class="form-validation-msgs">
                            <div
                                v-for="(errSlot, slotIndex) in errors"
                                :key="slotIndex"
                            >
                                <p
                                    v-for="(err, errorIndex) in errSlot"
                                    :key="errorIndex"
                                    class="text-error"
                                >
                                    {{ err }}
                                </p>
                            </div>
                        </div>
                        <div class="form-actions">
                            <button type="submit" class="btn btn-1">
                                Login
                            </button>
                            <div>
                                <router-link class="link link-2" :to="{name: 'app.public.lostPassword'}">
                                    Lost password
                                </router-link>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <div class="layout-image" />
    </div>
</template>

<script>
import {mapActions} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    name: "Login",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            user: {
                email: '',
                password: '',
            }
        }
    },
    methods: {
        ...mapActions({
            login: 'auth/login'
        }),
    }
}
</script>
