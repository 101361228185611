var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-layout"},[_c('div',{staticClass:"layout-content"},[_c('div',{staticClass:"content-header"},[_c('a',{staticClass:"logo",attrs:{"href":"https://www.aldoria.com/","target":"_blank"}}),_c('h1',{staticClass:"page-title"},[_vm._v(" Login ")]),_c('p',[_vm._v(" First time here ? "),_c('router-link',{staticClass:"link link-2",attrs:{"to":{name: 'app.public.accountActivation'}}},[_vm._v(" Activate your account ! ")])],1)]),_c('div',{staticClass:"content-body"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.login(_vm.user); })}}},[_c('ValidationProvider',{attrs:{"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref ){
var classes = ref.classes;
return [_c('div',{staticClass:"input-label-container"},[_c('label',{staticClass:"form-label",class:classes,attrs:{"for":"e-mail"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"input",class:classes,attrs:{"id":"e-mail","name":"Email","type":"email"},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"input-label-container"},[_c('label',{staticClass:"form-label",class:classes,attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.password),expression:"user.password"}],staticClass:"input",class:classes,attrs:{"id":"password","name":"Password","type":"password"},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "password", $event.target.value)}}})])]}}],null,true)}),_c('p',{staticClass:"input-tip"},[_vm._v(" 8 characters min. including at least one numeric digit and one special character. ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(invalid),expression:"invalid"}],staticClass:"form-validation-msgs"},_vm._l((errors),function(errSlot,slotIndex){return _c('div',{key:slotIndex},_vm._l((errSlot),function(err,errorIndex){return _c('p',{key:errorIndex,staticClass:"text-error"},[_vm._v(" "+_vm._s(err)+" ")])}),0)}),0),_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-1",attrs:{"type":"submit"}},[_vm._v(" Login ")]),_c('div',[_c('router-link',{staticClass:"link link-2",attrs:{"to":{name: 'app.public.lostPassword'}}},[_vm._v(" Lost password ")])],1)])],1)]}}])})],1)]),_c('div',{staticClass:"layout-image"})])}
var staticRenderFns = []

export { render, staticRenderFns }