<template>
    <div class="container page-content">
        <v-select v-model="selected" :options="books" label="title">
            <template #search="{attributes, events}">
                <input
                    class="vs__search"
                    :required="!selected"
                    v-bind="attributes"
                    v-on="events"
                >
            </template>
        </v-select>
    </div>
</template>

<script>

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import { mapState } from "vuex"


export default {
    name: 'ClientList',
    components: {
        vSelect,
    },
    data() {
        return {
            selectedEvent: null,
            maxDistance: 5000 // set here max 'miss distance' of events to be displayed in the fleet visualisation
        }
    },
    computed: {
        ...mapState('event', ['fleet', 'events']),
        eventsBySatellite() {
            const fleet = this.fleet;
            const eventsBySatellite = [];

            for (const satellite of fleet) {
                eventsBySatellite.push({
                    name: satellite.name,
                    events: []
                })
                for (const event of satellite.Events) {
                    if (event.Conjunctions[0].miss_distance <= this.maxDistance) eventsBySatellite[eventsBySatellite.length - 1].events.push({
                        ...event.Conjunctions[0],
                        obj: (event.Object && event.Object.name) ? event.Object.name : 'Analyst Object'
                    })
                }
            }
            return eventsBySatellite
        },
        isFleetEmpty() {
            for (const satellite of this.eventsBySatellite ) {
                if (satellite.events.length > 0) {
                    return false;
                }
            }
            return true;
        }
    },
    methods: {
        openPanel(eventId) {
            const clickedEvent = this.findEventById(eventId)
            this.selectedEvent = this.selectedEvent === clickedEvent ? null : clickedEvent;
        },
        findEventById(eventId) {
            let event;
            for (let satellite of this.fleet) {
                event = satellite.Events.find(event => event.event_id === eventId)
                if (event) break;
            }
            return event
        }
    }
}
</script>
