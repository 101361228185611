var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-layout"},[_c('div',{staticClass:"layout-content"},[_vm._m(0),_c('div',{staticClass:"content-body"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitPassword)}}},[_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"input-label-container"},[_c('label',{staticClass:"form-label",class:classes,attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"input",class:classes,attrs:{"id":"password","type":"password","name":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}})])]}}],null,true)}),_c('p',{staticClass:"input-tip"},[_vm._v(" 8 characters min. including at least one numeric digit and one special character. ")]),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"input-label-container"},[_c('label',{staticClass:"form-label",class:classes,attrs:{"for":"passwordConfirmation"}},[_vm._v("Password confirmation")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"input",class:classes,attrs:{"id":"passwordConfirmation","type":"password","name":"Password confirmation"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}})])]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(invalid),expression:"invalid"}],staticClass:"form-validation-msgs"},_vm._l((errors),function(errSlot,slotIndex){return _c('div',{key:slotIndex},_vm._l((errSlot),function(err,errorIndex){return _c('p',{key:errorIndex,staticClass:"text-error"},[_vm._v(" "+_vm._s(err)+" ")])}),0)}),0),_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-1"},[_vm._v(" Create password ")]),_c('div',[_c('router-link',{staticClass:"link link-2",attrs:{"to":{name: 'app.public.login'}}},[_vm._v(" < Back to login ")])],1)])],1)]}}])})],1)]),_c('div',{staticClass:"layout-image"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-header"},[_c('a',{staticClass:"logo",attrs:{"href":"https://www.aldoria.com","target":"_blank"}}),_c('h1',{staticClass:"page-title"},[_vm._v(" Create your password ")])])}]

export { render, staticRenderFns }