<template>
    <div v-if="user.hasCalmAPI" class="msg-on-hover">
        <button class="btn btn-small btn-2 input-form-height" @click="onUploadFileButtonClicked">
            + Send ephemerides
        </button>
        <input ref="uploadEphemeride" type="file" style="display:none" accept=".txt" @change="onFileUpload">
        <p class="on-hover-msg">
            Please check out the API documentation for<br>instructions on submissions of ephemeride files.
        </p>
    </div>
</template>

<script>
import filesAPI from "../services/files.js";
import Notification from "../Notification";
import { mapState } from "vuex";

export default {
    name: "UploadEphem",
    props: {
        tabname: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState('auth', ['user']),
    },
    methods: {
        onUploadFileButtonClicked() {
            this.$refs.uploadEphemeride.click();
        },
        onFileUpload(event) {
            const files = event.target.files;
            const filename = files[0].name;
            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
                const base64 = fileReader.result;
                filesAPI.uploadOrbitalSafetyData(filename, base64, this.tabname).then(() => {
                    Notification.success("Your file is being processed, please refresh the page later.");
                }, () => {
                    Notification.error("Failed to upload your data.");
                })
            });
            fileReader.readAsDataURL(files[0]);
        },
    }
}
</script>
