<template>
    <div class="container page-content">
        <div>
            <SatelliteControlPanelViz
                v-if="selectedSatellite && conjunctions.length > 0"
                :max-distance="maxDistance"
                :satellite-data="selectedSatellite"
                :conjunctions="conjunctions"
                @onMarkerClick="openPanel"
                @onUpdateDate="updateDate"
            />
            <div v-else class="no-data-msg">
                <DateSwitcher :date="selectedDate" @onUpdateDate="updateDate" />
                <hr class="line-separator">
                <p>No close approach event was detected for the next five days. Cataloged objects are not threatening your satellite(s) at the moment. </p>
                <hr class="line-separator">
            </div>
            <transition name="slide">
                <SatelliteControlPopinPanel v-if="selectedEvent" :satellite="selectedSatellite" :event="selectedEvent" @onClose="openPanel(null)" />
            </transition>
        </div>
    </div>
</template>

<script>
import SatelliteControlPanelViz from "../../components/SatelliteControlPanelViz";
import SatelliteControlPopinPanel from "../../components/SatelliteControlPopinPanel";
import {mapActions, mapGetters, mapState} from "vuex"
import DateSwitcher from "@/components/DateSwitcher";

export default {
    name: 'SatellitePanel',
    components: {
        DateSwitcher,
        SatelliteControlPanelViz,
        SatelliteControlPopinPanel,
    },
    data() {
        return {
            selectedEvent: null,
        }
    },
    computed: {
        ...mapState('event', ['maneuvers', 'maxDistance', 'selectedDate']),
        ...mapGetters('event', ['selectedSatellite']),
        selectedSatelliteConjunction() {
            const selectedSatelliteEvents = this.selectedSatellite ? [...this.selectedSatellite.Events] : [];

            return {
                name: 'Ref. No maneuver',
                sat_id: this.selectedSatellite.sat_id,
                events: selectedSatelliteEvents
                    .filter(event => event.Conjunctions[0].miss_distance <= this.maxDistance)
                    .map(event => {
                        const conjunction = event.Conjunctions[0];

                        return ({
                            ...event.Conjunctions[0],
                            Object: event.Object,
                            sourceT: conjunction.Scenario.Ephem_source.source_name,
                            sourceC: conjunction.ScenarioC.Ephem_source.source_name,
                        });
                    })
            }
        },
        conjunctions() {
            const conjunctions = [this.selectedSatelliteConjunction];

            for (const maneuver of this.maneuvers) {
                if (maneuver.Scenario?.Conjunctions && maneuver.Scenario.Conjunctions.length >= 1) {
                    let semiPeriod = "";
                    if (maneuver.semi_period === 1) semiPeriod = '-T/2';
                    else if (maneuver.semi_period % 2 === 0) {
                        if (maneuver.semi_period / 2 === 1) semiPeriod = '-T'
                        else semiPeriod = `-${maneuver.semi_period / 2}T`
                    } else semiPeriod = `-${maneuver.semi_period}T/2`

                    conjunctions.push({
                        // NOTE: deltav is in m/s and must be displayed in cm/s
                        name: `${maneuver.ux > 0 ? '+' : '-'}${maneuver.deltav * 100}cm/s, ${semiPeriod}`,
                        events: [...maneuver.Scenario.Conjunctions]
                            .filter(conjunction => conjunction.miss_distance <= this.maxDistance)
                            .map(conjunction => ({...conjunction, MM: maneuver, Scenario: maneuver.Conjunction.Scenario, ScenarioC: maneuver.Conjunction.ScenarioC, Object: {...conjunction.Event.Object}})),
                    });
                }
            }

            return conjunctions;
        },
    },
    methods: {
        ...mapActions('event', ['loadFleetSatelliteManeuversByDate']),
        openPanel(event) {
            this.selectedEvent = event;
        },
        updateDate(newDate) {
            this.loadFleetSatelliteManeuversByDate(newDate);
        },
    }
}
</script>
