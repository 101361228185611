import Private from "../views/private/Private";
import Account from "../views/private/Account";
import EventList from "../views/private/EventList";
import ClientList from "../views/private/ClientList";
import EventDetails from "../views/private/EventDetails";
import FleetPanel5km from "../views/private/FleetPanel5km";
import FleetPanel20km from "../views/private/FleetPanel20km";
import SatellitePanel from "../views/private/SatellitePanel";
import OrbitalSafetyPanel from "../views/private/OrbitalSafetyPanel";
import store from "../store/index"
import * as helper from "./helper"

export default {
    name: 'app.private',
    path: '/',
    component: Private,
    meta: {
        authRequired: true,
    },
    beforeEnter: (_to, _from, next) => {
        helper.handleError(
            store.dispatch('auth/getMyUser'),
        ).then(params => {
            if (store.state.auth.user.admin === 1) {
                helper.handleError(store.dispatch('auth/loadAllClients')).then(next)
            } else {
                next(params)
            }
        })
    },
    redirect: 'fleet-panel5km',
    children: [{
        name: 'app.private.clientsList',
        path: 'clients-list',
        component: ClientList,
        /*
        beforeEnter: (_from, _to, next) => {
            helper.handleError(
                store.dispatch('event/loadAllClients')
            ).then(next)
        }
        */
    }, {
        name: 'app.private.fleetPanel5km',
        path: 'fleet-panel5km',
        component: FleetPanel5km,
        beforeEnter: (_to, _from, next) => {
            helper.handleError(
                store.dispatch('event/loadFleet'),
                store.dispatch('event/loadSources')
            ).then(next)
        }
    }, {
        name: 'app.private.fleetPanel20km',
        path: 'fleet-panel20km',
        component: FleetPanel20km,
        beforeEnter: (_to, _from, next) => {
            helper.handleError(
                store.dispatch('event/loadFleet'),
                store.dispatch('event/loadSources')
            ).then(next)
        }
    }, {
        name: 'app.private.eventsList',
        path: 'events-list',
        component: EventList,
        beforeEnter: (_to, _from, next) => {
            helper.handleError(
                store.dispatch('event/loadAllEvents')
            ).then(next)
        }
    }, {
        name: 'app.private.satellitePanel',
        path: 'satellite-panel',
        component: SatellitePanel,
        props: false, // NOTE: (qui êtes vous ?): ajouter par moi
        beforeEnter: (to, _from, next) => {
            if (to.params.conjunctionId && to.params.satelliteId) {
                Promise.all(
                    [helper.handleError(
                        store.dispatch('event/loadSatellite', to.params.satelliteId),
                        store.dispatch('event/setSelectedConjunction', to.params.conjunctionId),
                        store.dispatch('event/loadManeuvers'),
                    )].concat(to.params.maxDistance ? [
                        helper.handleError(store.commit('event/setMaxDistance', to.params.maxDistance))
                    ] : [])
                ).then(next)
            } else if (!store.getters['event/canSeeConjunctionManeuvers']) {
                next({name: 'app.private.fleetPanel5km'});
            } else {
                helper.handleError(
                    store.dispatch('event/loadManeuvers'),
                ).then(next);
            }
        },
    }, {
        name: 'app.private.orbitalSafetyPanel',
        path: 'orbital-safety-panel',
        component: OrbitalSafetyPanel,
        beforeEnter: (_to, _from, next) => {
            helper.handleError(
                store.dispatch('event/loadOrbitalSafetyData')
            ).then(next)
        }
    }, {
        name: 'app.private.account',
        path: 'account',
        component: Account,
        beforeEnter: (_to, _from, next) => {
            helper.handleError(
                store.dispatch('auth/getMyUser')
            ).then(next)
        }
    }, {
        name: 'app.private.event',
        path: 'event/:id',
        component: EventDetails,
    }]
};
