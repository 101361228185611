<template>
    <div v-if="user">
        <div class="header">
            <div class="container">
                <a href="https://www.aldoria.com/" target="_blank" class="logo" />
                <div class="company-name">
                    {{ usurpedClientName || user.Client.name }}
                </div>
                <div class="header-links">
                    <button v-if="user.admin" class="link link-1" @click="toggleImpostorPopup()">
                        Admin Mode
                    </button>
                    <a href="https://calmapi.aldoria.com/" target="_blank" style="text-decoration: none; margin-right: 1.5em;">API Documentation</a>
                    <router-link class="user-name" active-class="active" :to="{ name: 'app.private.account' }">
                        {{ fullName }}
                    </router-link>
                    <button class="link link-1" @click="logout()">
                        Log out
                    </button>
                </div>
            </div>
        </div>
        <div class="main-nav">
            <div class="container">
                <div class="tabs">
                    <router-link v-if="user.hasFCPAccess" class="tab-item" active-class="active" :to="{ name: 'app.private.fleetPanel5km' }">
                        Fleet Control Panel 5 km
                    </router-link>
                    <router-link v-if="user.hasFCPAccess" class="tab-item" active-class="active" :to="{ name: 'app.private.fleetPanel20km' }">
                        Fleet Control Panel 20 km
                    </router-link>
                    <router-link v-if="user.hasFCPAccess" class="tab-item" active-class="active" :to="{ name: 'app.private.eventsList' }">
                        Events List
                    </router-link>
                    <router-link v-if="user.hasOSPAccess" class="tab-item" active-class="active" :to="{ name: 'app.private.orbitalSafetyPanel' }">
                        Orbital Safety Panel
                    </router-link>
                    <router-link v-show="canSeeConjunctionManeuvers" class="tab-item" active-class="active" :to="{ name: 'app.private.satellitePanel' }">
                        <span class="tab-label">Satellite Control Panel</span>
                        <button class="link close-tab" @click.prevent="closeSatelliteControlPanel()">
                            <IconBase width="20" height="20" icon-name="IconClose" icon-color="#3f5d9f">
                                <IconClose />
                            </IconBase>
                        </button>
                    </router-link>
                </div>
            </div>
            <AccountImpostorPopup v-if="showImpostorPopup" @onClose="toggleImpostorPopup()" />
        </div>
        <router-view />
    </div>
</template>

<script>
import {mapActions, mapState, mapGetters, mapMutations} from "vuex";
import AccountImpostorPopup from "@/components/AccountImpostorPopup";
import IconBase from "@/components/icons/IconBase";
import IconClose from "@/components/icons/IconClose";

export default {
    name: "Private",
    components: {
        IconClose,
        IconBase,
        AccountImpostorPopup,
    },
    data() {
        return {
            showImpostorPopup: false,
        };
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapGetters('auth', ['fullName']),
        ...mapGetters('event', ['canSeeConjunctionManeuvers']),
        ...mapState('auth', {
            usurpedClientName(state) {
                return state.clients.find(c => String(c.clientsId) === String(state.usurpedClient))?.name;
            }
        }),
    },
    methods: {
        ...mapActions({
            logout: 'auth/logout',
        }),
        ...mapMutations('event', ['setSelectedSatelliteId', 'setSelectedConjunction']),
        toggleImpostorPopup() {
            this.showImpostorPopup = !this.showImpostorPopup;
        },
        closeSatelliteControlPanel() {
            this.setSelectedSatelliteId(null);
            this.setSelectedConjunction(null);

            if (this.$router.currentRoute.name === 'app.private.satellitePanel') {
                this.$router.push({name: 'app.private'});
            }
        },
    },
}
</script>
