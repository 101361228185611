import userService from "../services/user";
import router from "../router";
import Notification from "../Notification";
import { getField, updateField } from "vuex-map-fields";
import errorsService from "../services/errors";

export default {
    namespaced: true,
    state: {
        user: userService.getLocalUser(),
        usurpedClient: userService.getImpersonatedClient(),
        clients: [],
    },
    actions: {
        logout({ commit }) {
            userService.logout().then(() => {
                userService.deleteLocalUser();
                userService.deleteImpersonatedClient();
                commit("deleteLocalUser");
                commit("setImpersonatedClient", null);
                router.push("/").catch(()=>{});
            }, errorsService.genericErrorNotificationBis);
        },
        login({ commit }, u) {
            userService.login(u).then(
                () => {
                    return userService.getMyself().then(res => {
                        commit("setLocalUser", {user: res.data});
                        userService.setLocalUser(res.data);
                        router.push({ name: "app.private" });
                    })
                },
                (err) => {
                    Notification.error(
                        "Authentication failed. Please check your entries and try again."
                    );
                    return err;
                }
            );
        },
        activateAccount(context, user) {
            userService.activateAccount(user).then(() => {
                Notification.success(
                    "An email has been sent to specified email address. Please follow the instructions there to activate your account and set your password."
                );
                router.push("/login");
            }, errorsService.genericErrorNotification);
        },
        lostPassword(context, user) {
            userService.lostPassword(user).then(() => {
                Notification.success(
                    "A link to reset your password has been sent to the specified email address. Please follow the instructions there to reset your password."
                );
            }, errorsService.genericErrorNotification);
        },
        resetPassword(context, pwd) {
            userService.resetPassword(pwd).then(() => {
                Notification.success(
                    "Your password has been reset successfully. You can now try to login."
                );
                router.push("/login");
            }, errorsService.genericErrorNotification);
        },
        updateMyUser({ commit }, user) {
            userService.updateUserInfos(user).then(() => {
                commit("mergeUser", { ...user });
                userService.setLocalUser({ ...user });
                Notification.success("Your account has been successfully updated.");
            }, errorsService.genericErrorNotification);
        },
        updateMyPassword({ state }, pwd) {
            userService
                .updatePasswordById(state.user.userId, pwd)
                .then(
                    () =>
                        Notification.success(
                            "Your password has been updated successfully."
                        ),
                    errorsService.genericErrorNotification
                );
        },
        createPassword(context, user) {
            userService.createPassword(user).then(() => {
                Notification.success(
                    "Your password has been set successfully and your account is now validated. You can now try to login."
                );
                router.push("/login");
            }, errorsService.genericErrorNotification);
        },
        getMyUser({ commit, state }) {
            return userService
                .getMyself()
                .then(
                    (res) => {
                        if (!state.user) {
                            commit("setLocalUser", {user: res.data});
                        } else {
                            commit("mergeUser", res.data);
                        }
                    },
                    errorsService.genericErrorNotificationBis,
                );
        },
        impersonateClient({commit}, clientsId) {
            return userService.impersonateUser(clientsId).then(
                () => {
                    userService.setImpersonatedClient(clientsId);
                    commit('setImpersonatedClient', clientsId);
                },
                errorsService.genericErrorNotification,
            );
        },
        loadAllClients({commit}) {
            return userService.getAllClients().then(
                (res) => commit("setAllClients", res.data),
                errorsService.genericErrorNotification,
            )
        },
    },
    mutations: {
        deleteLocalUser(state) {
            state.user = null;
        },
        setLocalUser(state, payload) {
            state.user = payload.user;
        },
        setAllClients(state, clients) {
            state.clients = clients;
        },
        mergeUser(state, user) {
            Object.assign(state.user, user);
        },
        setImpersonatedClient(state, clientId) {
            state.usurpedClient = clientId;
        },
        updateField,
    },
    getters: {
        isSetLocalUser: (state) => state.user !== null,
        fullName(state) {
            return (
                state.user.firstname[0] +
        ". " +
        state.user.lastname
            ).toUpperCase();
        },
        getField,
    },
};
