<template>
    <!-- set here max 'miss distance' of events to be displayed in the fleet visualisation -->
    <FleetPanelView :max-distance="5000" />
</template>

<script>
import FleetPanelView from "../../components/FleetPanelView";

export default {
    name: 'FleetPanel5km',
    components: {
        FleetPanelView,
    },
}
</script>
