import Notification from "../Notification"

export default {
    genericErrorNotification(error) {
        Notification.error("An unexpected system error has occured. Please try again later or contact an administrator if the problem persists.");
        return error
    },
    genericErrorNotificationBis(error) {
        Notification.success("Login again.");
        return error
    },
}
