<template>
    <div class="pop-up-background">
        <div class="pop-up">
            <form @submit.prevent="handleSubmit()">
                <p class="pop-up-title">
                    Choose a client to impersonate
                </p>

                <div class="row">
                    <div class="input-label-container col col-1-2">
                        <label class="form-label" for="client">Client</label>
                        <select
                            id="client"
                            v-model="selectedClientId"
                            class="select"
                        >
                            <option v-for="client in sortedClients" :key="client.clientsId" :value="client.clientsId">
                                {{ client.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="btn-center form-actions">
                    <button type="button" class="btn btn-2" @click="closePopup()">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-1">
                        Confirm
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "AccountImpostorPopup",
    data() {
        return {
            selectedClientId: this.$store.state.auth.usurpedClient || this.$store.state.auth.user.Client.clientsId,
        };
    },
    computed: {
        ...mapState('auth', {
            sortedClients(state) {
                return [...state.clients].sort((c1, c2) => c1.name.localeCompare(c2.name))
            }
        }),
    },
    methods: {
        ...mapActions('auth', ['impersonateClient']),
        ...mapActions('event', ['loadFleet', 'loadAllEvents']),
        handleSubmit() {
            this.impersonateClient(this.selectedClientId).then(() => {
                this.closePopup();
                this.loadFleet();
                this.loadAllEvents();
            });
        },
        closePopup() {
            this.$emit("onClose");
        }
    },
}
</script>
