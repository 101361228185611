import API from "./API"

export default {
    downloadFile(url, params, name = 'download') {
        return API({
            url: url,
            method: 'GET',
            responseType: 'blob',
            params,
        }).then((response) => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)
            fileLink.click()
            document.body.removeChild(fileLink)
        })
    },

    downloadData(timelapse) {
        let name = 'event-list';
        if (timelapse.startDate) name += '_start_' + timelapse.startDate
        if (timelapse.endDate) name += '_end_' + timelapse.endDate
        name += '.csv'
        return this.downloadFile(
            'calm/csv',
            timelapse,
            name,
        )
    },

    uploadOrbitalSafetyData(filename, data, tabname) {
        return API.post('calm/oem/upload', { filename, data, tabname });
    }
}
