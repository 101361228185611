var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container page-content"},[_c('h1',{staticClass:"page-title"},[_vm._v(" Your account ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"account-form",on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.updateMyUser(_vm.user); })}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-1-2 capped"},[_c('ValidationProvider',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"input-label-container"},[_c('label',{staticClass:"form-label",class:classes,attrs:{"for":"email"}},[_vm._v("Email (login) *")]),_c('p',{class:classes,attrs:{"id":"email","name":"Email"}},[_vm._v(" "+_vm._s(_vm.user.email)+" ")])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-1-2 capped"},[_c('ValidationProvider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"input-label-container"},[_c('label',{staticClass:"form-label",class:classes,attrs:{"for":"lastname"}},[_vm._v("Lastname *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.lastname),expression:"user.lastname"}],staticClass:"input",class:classes,attrs:{"id":"lastname","name":"Lastname"},domProps:{"value":(_vm.user.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "lastname", $event.target.value)}}})])]}}],null,true)})],1),_c('div',{staticClass:"col col-1-2 capped"},[_c('ValidationProvider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('label',{staticClass:"form-label",class:classes,attrs:{"for":"firstname"}},[_vm._v("Firstname *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.firstname),expression:"user.firstname"}],staticClass:"input",class:classes,attrs:{"id":"firstname","name":"Firstname"},domProps:{"value":(_vm.user.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "firstname", $event.target.value)}}})]}}],null,true)})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(invalid),expression:"invalid"}],staticClass:"form-validation-msgs"},_vm._l((errors),function(errSlot,slotIndex){return _c('div',{key:slotIndex},_vm._l((errSlot),function(err,errorIndex){return _c('p',{key:errorIndex,staticClass:"text-error"},[_vm._v(" "+_vm._s(err)+" ")])}),0)}),0),_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-1"},[_vm._v(" UPDATE ACCOUNT ")])])])]}}])}),_c('hr'),_c('h2',{staticClass:"page-subtitle"},[_vm._v(" Change your password ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"account-form",on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return _vm.updateMyPassword({newPwd: _vm.password}); })}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-1-2 capped"},[_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"input-label-container"},[_c('label',{staticClass:"form-label",class:classes,attrs:{"for":"password"}},[_vm._v("New password *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"input",class:classes,attrs:{"id":"password","name":"New password","type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}})])]}}],null,true)}),_c('p',{staticClass:"input-tip"},[_vm._v(" 8 characters min. including at least one numeric digit and one special character. ")])],1),_c('div',{staticClass:"col col-1-2 capped"},[_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"input-label-container"},[_c('label',{staticClass:"form-label",class:classes,attrs:{"for":"passwordConfirmation"}},[_vm._v("Password confirmation *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"input",class:classes,attrs:{"id":"passwordConfirmation","name":"Password confirmation","type":"password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}})])]}}],null,true)})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(invalid),expression:"invalid"}],staticClass:"form-validation-msgs"},_vm._l((errors),function(errSlot,slotIndex){return _c('div',{key:slotIndex,staticClass:"error-container"},_vm._l((errSlot),function(err,errorIndex){return _c('p',{key:errorIndex,staticClass:"text-error"},[_vm._v(" "+_vm._s(err)+" ")])}),0)}),0),_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-2"},[_vm._v(" CHANGE PASSWORD ")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }