<template>
    <div class="auth-layout">
        <div class="layout-content">
            <div class="content-header">
                <a href="https://www.aldoria.com" target="_blank" class="logo" />
                <h1 class="page-title">
                    Create your password
                </h1>
            </div>
            <div class="content-body">
                <ValidationObserver v-slot="{errors, handleSubmit, invalid}">
                    <form @submit.prevent="handleSubmit(submitPassword)">
                        <ValidationProvider
                            v-slot="{classes}"
                            rules="required|password"
                            vid="password"
                            slim
                        >
                            <div class="input-label-container">
                                <label class="form-label" :class="classes" for="password">Password</label>
                                <input
                                    id="password"
                                    v-model="password"
                                    class="input"
                                    :class="classes"
                                    type="password"
                                    name="Password"
                                >
                            </div>
                        </ValidationProvider>
                        <p class="input-tip">
                            8 characters min. including at least one numeric digit and one special character.
                        </p>
                        <ValidationProvider v-slot="{classes}" rules="required|confirmed:password" slim>
                            <div class="input-label-container">
                                <label for="passwordConfirmation" class="form-label" :class="classes">Password confirmation</label>
                                <input
                                    id="passwordConfirmation"
                                    v-model="confirmPassword"
                                    type="password"
                                    name="Password confirmation"
                                    class="input"
                                    :class="classes"
                                >
                            </div>
                        </ValidationProvider>

                        <div v-show="invalid" class="form-validation-msgs">
                            <div
                                v-for="(errSlot, slotIndex) in errors"
                                :key="slotIndex"
                            >
                                <p
                                    v-for="(err, errorIndex) in errSlot" :key="errorIndex"
                                    class="text-error"
                                >
                                    {{ err }}
                                </p>
                            </div>
                        </div>

                        <div class="form-actions">
                            <button class="btn btn-1">
                                Create password
                            </button>
                            <div>
                                <router-link class="link link-2" :to="{name: 'app.public.login'}">
                                    &lt;&nbsp;Back to login
                                </router-link>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <div class="layout-image" />
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {mapActions} from "vuex";

export default {
    name: "PasswordCreation",
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        guid: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            password: '',
            confirmPassword: '',
        }
    },
    methods: {
        ...mapActions({
            createPassword: "auth/createPassword"
        }),
        submitPassword() {
            this.createPassword({
                guid: this.guid,
                password: this.password
            })
        },
    }
}
</script>
