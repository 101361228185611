import eventService from "../services/event"
import errorsService from "../services/errors"
import moment from "moment";

export default {
    namespaced: true,
    state: {
        events: [],
        fleet: [],
        satellites: [],
        selectedSatelliteId: null,
        selectedConjunctionId: null,
        maneuvers: [],
        maxDistance: null,
        selectedDate: new Date(),
        orbitalSafetyData: [],
    },
    actions: {
        loadAllEvents({commit, dispatch}) {
            return eventService.getAllEvents().then((events) => {
                commit('setEvents', events.data)
                commit('setSelectedSatelliteId', null)
                return dispatch('loadSatellites').catch(errorsService.genericErrorNotification)
            }, errorsService.genericErrorNotification)
        },
        loadSatellites({state, commit}) {
            let satellites = []
            for (const event of state.events) {
                let satelliteAlreadyExist = false
                for (let index = 0; index < satellites.length && !satelliteAlreadyExist; index++) {
                    if (event.Satellite.sat_id === satellites[index].sat_id) satelliteAlreadyExist = true
                }
                if (!satelliteAlreadyExist) satellites.push(event.Satellite)
            }
            commit('setSatellites', satellites)
        },
        loadFleet({state, commit}) {
            return eventService.getFleet(moment(state.selectedDate).format("YYYY-MM-DD")).then(res => commit('setFleet', res.data),
                errorsService.genericErrorNotification)
        },
        loadSources({commit}) {
            return eventService.getAllSources().then(res => commit('setSources', res.data),
                errorsService.genericErrorNotification)
        },
        loadSatellite({commit}, satId) {
            return commit('setSelectedSatelliteId', satId);
        },
        loadManeuvers({state, commit}) {
            if (!state.selectedSatelliteId || !state.selectedConjunctionId) return Promise.reject();
            else {
                return eventService.getManeuvers(state.selectedConjunctionId, moment(state.selectedDate).format("YYYY-MM-DD")).then(
                    res => commit('setManeuvers', res.data),
                    () => errorsService.genericErrorNotification(),
                );
            }
        },
        setSelectedConjunction({commit}, selectedConjunctionId) {
            return commit('setSelectedConjunction', selectedConjunctionId);
        },
        loadFleetSatelliteManeuversByDate({commit, dispatch, state}, newDate) {
            commit('setSelectedDate', newDate);
            return dispatch('loadFleet')
                .then(() => dispatch('loadSatellite', state.selectedSatelliteId))
                .then(() => dispatch('loadManeuvers'));
        },
        loadOrbitalSafetyData({commit}) {
            return eventService.getOrbitalSafetyData().then(
                res => commit('setOrbitalSafetyData', res.data),
                errorsService.genericErrorNotification,
            );
        }
    },
    mutations: {
        setSelectedDate(state, newDate) {
            state.selectedDate = newDate;
        },
        setEvents(state, events) {
            state.events = events
        },
        setSatellites(state, satellites) {
            state.satellites = satellites;
        },
        setSelectedSatelliteId(state, satId) {
            state.selectedSatelliteId = satId;
        },
        setFleet(state, fleet) {
            state.fleet = fleet
        },
        setSources(state, sources) {
            state.sources = sources
        },
        setManeuvers(state, maneuvers) {
            state.maneuvers = maneuvers;
        },
        setSelectedConjunction(state, selectedConjunctionId) {
            state.selectedConjunctionId = selectedConjunctionId;
        },
        setMaxDistance(state, maxDistance) {
            state.maxDistance = maxDistance;
        },
        setOrbitalSafetyData(state, orbitalSafetyData) {
            state.orbitalSafetyData = orbitalSafetyData;
        },
    },
    getters: {
        canSeeConjunctionManeuvers(state) {
            return state.selectedSatelliteId && state.selectedConjunctionId && state.maxDistance;
        },
        selectedSatellite(state) {
            return state.fleet.find(sat => sat.sat_id === state.selectedSatelliteId);
        },
    },
}
