<template>
    <div :id="`orbital-panel-container-${data.oem_id}`" ref="orbitalPanelContainer" class="orbital-panel-container">
        <div :id="`orbital-panel-${data.oem_id}`" ref="orbitalPanel" class="orbital-panel" />
    </div>
</template>

<script>
import * as d3 from "d3";
import moment from 'moment';
import formatDate from '../filters/formatDate';
import formatDistance from '../filters/formatDistance';
import formatProbability from '../filters/formatProbability';

export default {
    name: 'OrbitalSafetyPanelViz',
    props: {
        data: {
            type: Object,
            required: true
        },
        maxDistance: {
            type: Number,
            default: 5000,
        },
    },
    watch: {
        data() {
            this.$refs.orbitalPanel.innerHTML = null;
            this.generateViz();
        },
    },
    mounted() {
        // VIZ initialization
        this.generateViz();

        this.enableDragScrollingX(this.$refs.orbitalPanelContainer);

        // Reloading VIZ when resizing screen
        let timeoutId;
        window.addEventListener("resize", () => {
            if (!this.$refs.orbitalPanel) {
                return false;
            }
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                this.$refs.orbitalPanel.innerHTML = null;
                this.generateViz();
            }, 100);
        });
    },
    methods: {
        // Enabling X axis drag scrolling on the element passed in parameters
        enableDragScrollingX(element) {
            let isDown = false;
            let startX;
            let scrollLeft;

            element.addEventListener("mousedown", e => {
                isDown = true;
                startX = e.pageX - element.offsetLeft;
                scrollLeft = element.scrollLeft;
            });
            element.addEventListener("mouseleave", () => {
                isDown = false;
            });
            element.addEventListener("mouseup", () => {
                isDown = false;
            });
            element.addEventListener("mousemove", e => {
                if (isDown) {
                    e.preventDefault();
                    const x = e.pageX - element.offsetLeft;
                    const walk = x - startX;
                    element.scrollLeft = scrollLeft - walk;
                }
            });
        },
        generateViz() {
            // data
            const data = this.data;

            // config
            const rowHeight = 50;
            const rowPadding = 22;
            const rowHeaderWidth = 250;
            const maxMarkerRadius = rowHeight / 2 - 5;
            const minMarkerRadius = 5;
            const transitionDuration = 400;
            const transitionDelayByItem = 50;
            const colors = ["#95162e", "#c73434", "#ecb045", "#eee584", "#2ab16d"]; // purple, red, orange, yellow, green

            // panel dynamic dimensions
            const w = document.getElementById(`orbital-panel-${data.oem_id}`).clientWidth;
            const h = 2 * rowHeight; // as many rows as entries in data + 1 for xAxis

            // set min scale date to min date in events data, set to midnight to start axis to the beginning of the day
            const minDate = moment(data.start_time);
            // set max scale date to min date in events data, set to next day at midnight to start axis to the beginning of the day
            const maxDate = moment(data.end_time);

            const timeScale = d3
                .scaleTime()
                .domain([minDate, maxDate])
                .range([rowHeaderWidth + rowPadding, w - rowPadding]);

            const xAxis = d3.axisBottom(timeScale)
                .tickFormat(function(date) {
                    if (!date.getHours()) {
                        return d3.timeFormat("%b %d")(date);
                    } else {
                        return d3.timeFormat("%H:%M")(date);
                    }
                });

            // marker size ( / miss distance ) scale
            // TODO (opt) : linear for now, may become custom scale
            const sizeScale = d3
                .scaleLinear()
                .domain([this.maxDistance, 0])
                .range([minMarkerRadius, maxMarkerRadius]);

            // discrete color scale
            const colorScale = function(d) {
                let selectedColor;
                let value = d.collision_probability_max || d.collision_probability;
                if (!value || d.cdm_id) {
                    // if external event (cdm_id not null) or missing probability value
                    // => display in SMS blue, which is not a color of the scale
                    selectedColor = "#3f5d9f";
                } else if (value >= 1e-3) {
                    // purple above 1e-3
                    selectedColor = colors[0];
                } else if (value >= 1e-4) {
                    // red bewteen 1e-4 and 1e-3
                    selectedColor = colors[1];
                } else if (value >= 1e-5) {
                    // orange bewteen 1e-5 and 1e-4
                    selectedColor = colors[2];
                } else if (value >= 1e-6) {
                    // yellow bewteen 1e-6 and 1e-5
                    selectedColor = colors[3];
                } else {
                    // green if less than 1e-6
                    selectedColor = colors[4];
                }
                return selectedColor;
            };

            // Detect touch screen
            const hasTouch = function() {
                return "ontouchstart" in document.documentElement;
            };

            // Create tooltip
            const tooltip = d3
                .select(`#orbital-panel-${data.oem_id}`)
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);

            // **** LEGENDS
            // a) distance legend
            const itemsNb = d3.selectAll("#distance-legend .legend-item")
                .size();
            d3.selectAll("#distance-legend .legend-item")
                .style("width", function(d, i) {
                    return (
                        (minMarkerRadius +
                            ((itemsNb - 1 - i) * (maxMarkerRadius - minMarkerRadius)) /
                            (itemsNb - 1)) *
                        2 +
                        "px"
                    );
                })
                .style("height", function(d, i) {
                    return (
                        (minMarkerRadius +
                            ((itemsNb - 1 - i) * (maxMarkerRadius - minMarkerRadius)) /
                            (itemsNb - 1)) *
                        2 +
                        "px"
                    );
                });

            // a) probability legend
            d3.selectAll("#probability-legend .legend-item")
                .style(
                    "background-color",
                    function(d, i) {
                        return colors[i];
                    },
                );

            // **** 0. VIZ/SVG CREATION
            const viz = d3
                .select(`#orbital-panel-${data.oem_id}`)
                .append("svg")
                .attr("width", w)
                .attr("height", h);

            // **** 1. PRIMARY OBJECTS ROW AS SVG GROUPS
            const rows = viz
                .selectAll("g")
                .data([data])
                .enter()
                .append("g")
                .attr("transform", (d, i) => "translate(0," + (i * rowHeight + 1) + ")")
                .attr("class", "orbital-panel-row");

            rows
                .append("rect") // row background as a rectangle
                .attr("class", "row-bg") // stylized with CSS class .row-bg
                .attr("x", 0)
                .attr("y", 0)
                .transition()
                .duration(transitionDuration)
                .attr("width", w) // width 100%
                .attr("height", rowHeight - 1) // 1px less to create a separator between rows
                .delay((d, i) => i * transitionDelayByItem);

            rows
                .append("rect") // line passing through circles centers
                .attr("class", "row-line") // stylized with CSS class .row-bg
                .attr("x", 0)
                .attr("y", rowHeight / 2 - 1)
                .transition()
                .duration(transitionDuration)
                .attr("width", w) // width 100%
                .attr("height", 2) //
                .delay((d, i) => i * transitionDelayByItem);

            rows
                .append("rect") // row header as a rectangle
                .attr("class", "row-header") // stylized with CSS class .row-header
                .transition()
                .duration(transitionDuration)
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", rowHeaderWidth)
                .attr("height", rowHeight - 1) // cf. row sizing > separating rows
                .delay((d, i) => i * transitionDelayByItem);

            rows
                .append("text") // object (satellite) name
                .text(function(d) {
                    let name = String(d.oem_id);
                    // display object name with ellipses if too long
                    let maxSize = rowHeaderWidth / 10; // roughly 10px / character
                    return `${name.length > maxSize ? name.substr(0, maxSize) + "..." : name} (${minDate.format("YYYY/MM/DD")})`;
                })
                .attr("y", rowHeight / 3)
                .attr("x", rowPadding)
                .attr("dy", rowHeight / 4);

            // **** 2. FOR EACH ROW
            rows
                .selectAll("circle")
                .data(d => d.Scenario.Conjunctions)
                .enter()
                .append("circle")
                .attr("class", "marker")
                .attr("cx", function(d) {
                    return timeScale(new Date(d.tca));
                })
                .attr("cy", rowHeight / 2)
                .on("mouseover", function(d) {
                    if (!hasTouch()) {
                        tooltip
                            .transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        tooltip
                            .html(`
                                <div class="name">
                                    <strong>${d.Event.Object.name}</strong>
                                </div>
                                <div class="norad">
                                    <strong>NORAD ID:</strong>
                                    ${d.Event.Object.sat_id}
                                </div>
                                <div class="name">
                                    <strong>Origin:</strong>
                                    ${d.originator}
                                </div>
                                <br>
                                <div class="date">
                                    <strong>
                                        ${formatDate(d.tca, "YYYY/MM/DD")}
                                    </strong>
                                </div>
                                <div class="time">
                                    ${formatDate(d.tca, "HH:mm:ss.SSS")}
                                </div>
                                <br>
                                <div class="miss-distance">
                                    <strong>Miss dist.:</strong>
                                    ${formatDistance(d.miss_distance)}m
                                </div>` + (!d.cdm_id ? `
                                <div class="probability">
                                    <strong>Prob.: </strong>
                                    ${formatProbability(d.collision_probability)}
                                </div>
                                <div class="probability-max">
                                    <strong>Max. prob.: </strong>
                                    ${formatProbability(d.collision_probability_max)}
                                </div>` : ``)).style("left", d3.event.pageX - 150 + "px")
                            .style("top", d3.event.pageY - 28 + "px");
                    }
                })
                .on("mouseout", function() {
                    if (!hasTouch()) {
                        tooltip
                            .transition()
                            .duration(500)
                            .style("opacity", 0);
                    }
                })
                .on("click", d => this.$emit("onMarkerClick", d.event_id))
                .transition()
                .duration(transitionDuration)
                .attr("r", d => sizeScale(d.miss_distance))
                .attr("data-miss", d => d.miss_distance)
                .style("fill", d => colorScale(d));
            // .delay((d, i) => (i + data.length / 2) * (transitionDelayByItem * 2));
            // TODO : delete if transition removal is validated by SMS team

            // **** 3. ADD AXIS
            viz
                .append("g")
                .call(xAxis)
                .attr("transform", "translate(0," + (h - rowHeight + 10) + ")");
        },
    },
}
</script>
