import router from "./"

export function handleError(...promises) {
    return Promise.all(promises).then(
        results => {
            for (const res of results) {
                if (res instanceof Error) {
                    if (router.currentRoute.path === '/') {
                        router.push({name: 'app.public.error', params: { errorMessage: res.message } })
                    }
                    return Promise.reject(res)
                }
            }
            return results
        },
        err => {
            if (router.currentRoute.path === '/') {
                if (err instanceof Error) {
                    router.push({name: 'app.public.error', params: { errorMessage: err.message } })
                } else {
                    router.push({name: 'app.public.error' })
                }
            }
            return err
        })
}
