<template>
    <div class="pop-up-background">
        <div class="pop-up">
            <form @submit.prevent="handleSubmit()">
                <p class="pop-up-title">
                    {{ mainMessage }}
                </p>
                <div class="row">
                    <div class="col col-1-2">
                        <label class="form-label" for="start-date">Start date</label>
                        <input
                            id="start-date"
                            v-model="startDate"
                            name="Start date"
                            class="input"
                            type="date"
                            :min="firstDate"
                        >
                    </div>
                    <div class="col col-1-2">
                        <label class="form-label" for="end-date">End date</label>
                        <input
                            id="end-date"
                            v-model="endDate"
                            name="End date"
                            class="input"
                            type="date"
                            :min="startDate"
                        >
                    </div>
                </div>

                <div class="btn-center form-actions">
                    <button type="button" class="btn btn-2" @click="$emit('cancel')">
                        {{ cancelationMessage }}
                    </button>
                    <button type="submit" class="btn btn-1">
                        {{ confirmationMessage }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import moment from "moment"

const MIN_DATE = moment(new Date()).set({hour: 0, minute: 0, second: 0, millisecond: 0})

export default {
    name: 'DownloadDataPopup',
    props: {
        mainMessage: {
            type: String,
            default: "Download data"
        },
        confirmationMessage: {
            type: String,
            default: "Download"
        },
        cancelationMessage: {
            type: String,
            default: "Cancel"
        },
        lastEventDate: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            confirm: false,
            firstDate: MIN_DATE.format("YYYY-MM-DD"),
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('confirm', {startDate: this.startDate, endDate: this.endDate})
        }
    }
}
</script>
