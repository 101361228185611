import API from "./API";

export default {
    logout() {
        return API.get('/logout');
    },

    login(user) {
        return API.post('/login', user);
    },

    getMyself() {
        return API.get('/users/myself');
    },

    getLocalUser() {
        return JSON.parse(localStorage.getItem('user'));
    },

    setLocalUser(user) {
        return localStorage.setItem('user', JSON.stringify(user));
    },

    isSetLocalUser() {
        return localStorage.getItem('user') !== null;
    },

    deleteLocalUser() {
        return localStorage.removeItem('user');
    },

    getAllClients() {
        return API.get('/admin/clients')
    },

    activateAccount(user) {
        return API.post('/activate', user);
    },

    lostPassword(user) {
        return API.post('/users/reset-pwd', user);
    },

    resetPassword(pwd) {
        return API.post('/users/update-pwd', pwd);
    },

    getUserById(id) {
        return API.get('/users/' + id);
    },

    updatePasswordById(id, pwd) {
        return API.put('/users/' + id + "/update-pwd", pwd)
    },

    createPassword(user) {
        return API.post('/users', user)
    },

    updateUserInfos(user) {
        return API.put('/users/' + user.userId, user)
    },

    impersonateUser(clientsId) {
        return API.post(`/admin/clients`, {clientId: clientsId});
    },

    getImpersonatedClient() {
        return localStorage.getItem('impersonatedClient');
    },

    setImpersonatedClient(clientsId) {
        return localStorage.setItem('impersonatedClient', clientsId);
    },

    deleteImpersonatedClient() {
        return localStorage.removeItem('impersonatedClient');
    }
};
