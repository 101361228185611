<template>
    <div class="auth-layout">
        <div class="layout-content">
            <div class="content-header">
                <a href="https://www.aldoria.com" target="_blank" class="logo" />
                <h1 class="page-title">
                    Activate your account
                </h1>
                <p>
                    Your account has been created by an administrator but you didn’t activate it yet. Please fill in the form below to confirm your email and receive a
                    link to activate your account and create your password.
                </p>
            </div>
            <div class="content-body">
                <ValidationObserver v-slot="{errors, handleSubmit, invalid}">
                    <form @submit.prevent="handleSubmit(() => activateAccount(user))">
                        <div>
                            <ValidationProvider v-slot="{classes}" rules="required|email">
                                <div class="input-label-container">
                                    <label class="form-label" :class="classes" for="e-mail">Email</label>
                                    <input
                                        id="e-mail" v-model="user.email" name="Email"
                                        class="input" placeholder="e.g. john.doe@mail.com"
                                        :class="classes"
                                        type="email"
                                    >
                                </div>
                            </ValidationProvider>
                        </div>
                        <div v-show="invalid" class="form-validation-msgs">
                            <div v-for="(errSlot, slotIndex) in errors" :key="slotIndex">
                                <p v-for="(err, index) in errSlot" :key="index" class="text-error">
                                    {{ err }}
                                </p>
                            </div>
                        </div>
                        <div class="form-actions">
                            <button class="btn btn-1">
                                Send activation link
                            </button>
                            <div>
                                <router-link class="link link-2" :to="{name: 'app.public.login'}">
                                    &lt;&nbsp;Back to login
                                </router-link>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
        <div class="layout-image" />
    </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {mapActions} from "vuex";

export default {
    name: "AccountActivation",
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    data() {
        return {
            user: {
                email: '',
            }
        }
    },
    methods: {
        ...mapActions({
            activateAccount: 'auth/activateAccount',
        }),
    }
}
</script>
