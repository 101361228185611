import moment from 'moment'

export default function(value, format, utc = true) {
    if (value != null) {
        let momentValue = value instanceof moment ? value : moment(String(value));
        if (utc) momentValue = momentValue.utc()
        return momentValue.format(format)
    } else {
        return ""
    }
}

